import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.scss';
import routes from './Routes';
import Layout from './Routes/Layout';
import Page404 from './Componets/Page404/Page404';

function App() {
  const router = createBrowserRouter([
    {
      element: <Layout />,
      errorElement: window.location.pathname === '/500' ? <Page404 /> : <Page404 />,
      children: routes
    }
  ]);
  return <RouterProvider router={router} />;
}

export default App;
