import React from 'react'
import './Footer.scss'
import logo from '../../Assets/Img/AccoHisabLogo.png'
import { useNavigate } from 'react-router-dom'

function Footer() {

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section id='Footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-6 col-md-3 footer-flex'>
            <div className='details'>
              <img src={logo} alt='gadhiya technologies'></img>
              <p> AccoHisab is a cloud-based software for entrepreneurs and small businesses, streamlining tasks and boosting efficiency.</p>
              <div className='social-icon'>
                <i className='pi pi-linkedin' onClick={() => window.open('https://www.linkedin.com/company/gadhiya-technologies', '_blank')}></i>
                <i className='pi pi-instagram' onClick={() => window.open('https://www.instagram.com/gadhiyatechnologies', '_blank')}></i>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-md-3 footer-flex'>
            <div className='title'>
              <div className='title-name'>
                <p>Links</p>
              </div>
              <div className='name'>
                <p className='borders' onClick={() => handleNavigation('/')}>Home</p>
                <p className='borders' onClick={() => handleNavigation('/about')}>About us</p>
                <p className='borders' onClick={() => handleNavigation('/pricing')}>Pricing</p>
                <p className='borders' onClick={() => handleNavigation('/contactus')}>Contact</p>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-md-3 footer-flex'>
            <div className='title'>
              <div className='title-name'>
                <p>AccoHisab Software</p>
              </div>
              <div className='name'>
                <p>Billing Software</p>
                <p>Accounting Software</p>
                <p>Inventory Software</p>
                <p>Invoicing Software</p>
                <p>Business Management Software</p>
              </div>
            </div>
          </div>
          <div className='col-sm-6 col-md-3 footer-flex'>
            <div className='title'>
              <div className='title-name'>
                <p>Contact Info</p>
              </div>
              <div className='name'>
                <div className='addres'>
                  <img onClick={() => window.open('https://maps.app.goo.gl/UuPepmMdfoT3LeC6A', '_blank')} src={require('../../Assets/Img/icons_pin.webp')} alt='accounting software' />
                  <p onClick={() => window.open('https://maps.app.goo.gl/UuPepmMdfoT3LeC6A', '_blank')}>219, Parle square, near by Akshar wadi, Dabholi Char Rasta, Pramukh Darshan Society, Dabholi, Surat, Gujarat 395004 </p>
                </div>
                <div className='addres'>
                  <img src={require('../../Assets/Img/Phone.webp')} alt='gadhiya software' onClick={() => window.open('tel:+919054053820', "_blank")} />
                  <p onClick={() => window.open('tel:+919054053820', "_blank")}>+91 90540 53820</p>
                </div>
                <div className='addres'>
                  <img src={require('../../Assets/Img/icons_email.webp')} alt='gadhiya software' onClick={() => window.open('mailto:info@gadhiyatechnologies.com')} />
                  <p onClick={() => window.open('mailto:info@gadhiyatechnologies.com')}>info@gadhiyatechnologies.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='copyright'>
        <p>Copyright © 2023 All Rights Reserved</p>
        <div className='term'>
          <p onClick={() => handleNavigation('termsofuse')}>Term Of Use</p>
          <p onClick={() => handleNavigation('privacypolicy')}>Privacy Policy</p>
          <p onClick={() => handleNavigation('paymentterms')}>Payment Terms and Conditions</p>
          <p onClick={() => handleNavigation('refundpolicy')}>Refund & Cancellation Policy</p>
        </div>
      </div>
    </section >
  )
}

export default Footer