import React, { Suspense } from 'react'
import Header from '../Componets/Header/Header'
import Footer from '../Componets/Footer/Footer'
import { Outlet } from 'react-router-dom'

function Layout() {
    return (
        <div>
            <Header />
            <Suspense><Outlet /></Suspense> 
            <Footer />
        </div>
    )
}

export default Layout