const PathConstants = {
    Home: '/',
    Pricing: 'pricing',
    ContactUs: 'contactus',
    About: 'about',
    TermsOfUse: 'termsofuse',
    PaymentTerms: 'paymentterms',
    RefundPolicy: 'refundpolicy',
    PrivacyPolicy: 'privacypolicy',
}

export default PathConstants;