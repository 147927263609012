import React, { useState } from 'react';
import './Header.scss';
import logo from '../../Assets/Img/AccoHisabLogo.png';
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsNavCollapsed(true);
  };

  const toggleNavbar = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  return (
    <header id="Header">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="navbar-brand logo" onClick={() => handleNavigation('/')}>
            <img src={logo} alt="accounting software" />
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isNavCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <button className="nav-link" onClick={() => handleNavigation('/')} aria-current="page"  >
                  Home
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" href="/"  onClick={() => handleNavigation('/about')}>
                  About Us
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={() => handleNavigation('/pricing')}  >
                  Pricing
                </button>
              </li>
              <li className="nav-item">
                <button className="nav-link" onClick={() => handleNavigation('/contactus')}  >
                  Contact
                </button>
              </li>

              <li className="nav-item login-btn" onClick={() => window.open('https://a.accohisab.com/', '_blank')}>
                <button className="nav-link" >
                  Login
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
