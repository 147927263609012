import React from "react"
import PathConstants from '../Routes/pathConstants'

const Home = React.lazy(() => import('../Componets/Pages/Home/Home'));
const Pricing = React.lazy(() => import('../Componets/Pricing/Pricing'));
const ContactUs = React.lazy(() => import('../Componets/ContactUs/ContactUs'));
const About = React.lazy(() => import('../Componets/About/About'));
const TermsOfUse = React.lazy(() => import('../Componets/TermsOfUse/TermsOfUse'));
const PaymentTerms = React.lazy(() => import('../Componets/PaymentTerms/PaymentTerms'));
const RefundPolicy = React.lazy(() => import('../Componets/RefundPolicy/RefundPolicy'));
const PrivacyPolicy = React.lazy(() => import('../Componets/PrivacyPolicy/PrivacyPolicy'));

const routes = [
    { path: PathConstants.Home, element: <Home /> },
    { path: PathConstants.Pricing, element: <Pricing /> },
    { path: PathConstants.ContactUs, element: <ContactUs /> },
    { path: PathConstants.About, element: <About /> },
    { path: PathConstants.TermsOfUse, element: <TermsOfUse /> },
    { path: PathConstants.PaymentTerms, element: <PaymentTerms /> },
    { path: PathConstants.RefundPolicy, element: <RefundPolicy /> },
    { path: PathConstants.PrivacyPolicy, element: <PrivacyPolicy /> },
]

export default routes;
